
import { ListCategories } from "@/services/v1/admin/learning/categories/types";
import { gpf } from "@/utils/global-functions";
import { popupStatus } from "@/utils/popup/status";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  nextTick,
  reactive,
  ref,
  watch,
} from "vue";

const ModalTemplate = defineAsyncComponent(
  () => import(`@/components/modal-template/Main.vue`)
);

const TextField = defineAsyncComponent(
  () => import(`@/global-components/input/text-field/Main.vue`)
);

const SingleImageUpload = defineAsyncComponent(
  () => import(`@/global-components/input/single-image-upload/Main.vue`)
);

const Autocomplete = defineAsyncComponent(
  () => import(`@/global-components/input/autocomplete/Main.vue`)
);

const RadioGroup = defineAsyncComponent(
  () => import(`@/global-components/input/radio-group/Main.vue`)
);

const servicesV1AdminLearningCategories = async () =>
  import(`@/services/v1/admin/learning/categories`);

const servicesV1AdminLearningTopic = async () =>
  import(`@/services/v1/admin/learning/topic`);

export default defineComponent({
  props: {
    showFormTopic: {
      type: Boolean,
      default: false,
    },
    topicId: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ModalTemplate,
    TextField,
    SingleImageUpload,
    Autocomplete,
    RadioGroup,
  },
  setup(props, { emit }) {
    // START === Constant variable ===============================================
    const modalId = "modal-form-topic";
    const topicId = computed(() => props.topicId);
    const isEditPage = computed(() => !!topicId.value);

    const titleForm = computed(() =>
      isEditPage.value ? "Form Edit Topik" : "Form Add Topik"
    );

    const listStatus = [
      {
        text: "Active",
        value: "1",
      },
      {
        text: "Inactive",
        value: "0",
      },
    ];

    // END === Constant variable =================================================

    // START === Function for get list categories ============================================
    const categories = ref([] as any[]);
    const getCategories = async () => {
      const {
        getAdminLearningCategories,
      } = await servicesV1AdminLearningCategories();

      try {
        const hit = await getAdminLearningCategories();

        const resData = hit?.response;

        categories.value = resData.map((category: ListCategories) => ({
          ...category,
          text: category.title,
          value: category.id.toString(),
        }));
      } catch (err: any) {
        console.error("err getCategories ", err, err?.response);
      }
    };
    // END === Function for get list categories ==============================================

    // START === for handle show hide modal form topic ====================================
    const hideFormCategory = () => {
      resetForm();

      emit("update:show-form-topic", false);
      emit("update:topic-id", 0);
    };

    watch(
      () => props.showFormTopic,
      (newVal) => {
        console.log("props.showFormTopic newVal ", props);

        if (newVal) gpf.showModal(`#${modalId}`);
        gpf.onHideModal(`#${modalId}`, hideFormCategory);

        // fetch initial another data
        getCategories();
      }
    );
    // END === for handle show hide modal form topic ======================================

    // START === Form Data =================================================================
    const form = reactive({
      title: "",
      banner: {
        file: "",
        fileUrl: "",
      },
      idCategory: "",
      isActive: "0",
    });

    const resetForm = () => {
      form.title = "";
      form.banner = {
        file: "",
        fileUrl: "",
      };
      form.idCategory = "";
      form.isActive = "0";
    };

    // END === Form Data =================================================================

    // START === Functions handle get detail topic ==========================================
    const getDetailCategory = async () => {
      const {
        getAdminLearningTopicById,
      } = await servicesV1AdminLearningTopic();

      gpf.gLoading.show();

      try {
        const hit = await getAdminLearningTopicById(topicId.value.toString());

        await nextTick();

        const resData = hit.response;
        console.log("resData getDetailCategory ", resData);

        form.title = resData.title;
        form.banner.fileUrl = resData.banner;
        form.isActive = resData.isActive ? "1" : "0";

        console.log("form ", form);
      } catch (err: any) {
        console.log("err response getDetailCategory ", err, err?.response);

        gpf.handleEds(err);
      } finally {
        gpf.gLoading.hide();
      }
    };

    watch(
      () => isEditPage.value,
      (newVal) => {
        console.log("newVal isEditPage.value ", newVal);

        if (newVal) getDetailCategory();
      }
    );
    // END === Functions handle get detail topic ============================================

    // START === Function actions =========================================================
    const onCancelClick = () => gpf.hideModal(`#${modalId}`);

    const onSaveClick = () => {
      console.log("topic ID ", topicId.value);
      console.log("topic Slug ", topicId.value);

      if (isEditPage.value) {
        hitEditCategory();
      } else {
        hitAddCategory();
      }
    };

    const afterClickSaveCategory = () => {
      gpf.hideModal(`#${modalId}`);
      emit("update:refetch", true);
    };

    const hitAddCategory = async () => {
      const { postAdminLearningTopic } = await servicesV1AdminLearningTopic();

      gpf.gLoading.show();

      const body = new FormData();
      body.append("title", form.title);
      body.append("banner", form.banner.file);
      body.append("isActive", form.isActive === "1" ? "1" : "0");
      body.append("idCategory", form.idCategory);

      try {
        await postAdminLearningTopic(body);

        popupStatus({
          type: "s",
          message: "Successfully Created Topik",
        }).show();

        afterClickSaveCategory();
      } catch (err: any) {
        console.error("err hitAddCategory ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();
      }
    };

    const hitEditCategory = async () => {
      const { putAdminLearningTopic } = await servicesV1AdminLearningTopic();

      gpf.gLoading.show();

      const body = new FormData();
      body.append("title", form.title);

      const fileBanner = form.banner.file;
      if (fileBanner) {
        body.append("banner", fileBanner);
      } else {
        body.append("banner", "");
      }

      body.append("isActive", form.isActive === "1" ? "1" : "0");
      body.append("idCategory", form.idCategory);

      try {
        await putAdminLearningTopic(topicId.value.toString(), body);

        popupStatus({
          type: "s",
          message: "Successfully Updated Topik",
        }).show();

        afterClickSaveCategory();
      } catch (err: any) {
        console.error("err hitEditCategory ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();
      }
    };
    // END === Function actions ===========================================================

    return {
      modalId,
      isEditPage,
      titleForm,
      listStatus,
      form,
      categories,

      onCancelClick,
      onSaveClick,
    };
  },
});
