import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_SingleImageUpload = _resolveComponent("SingleImageUpload")!
  const _component_Autocomplete = _resolveComponent("Autocomplete")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_ModalTemplate = _resolveComponent("ModalTemplate")!

  return (_openBlock(), _createBlock(_component_ModalTemplate, {
    "modal-id": _ctx.modalId,
    title: _ctx.titleForm,
    size: "xl"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TextField, {
          value: _ctx.form.title,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event)),
          label: "Title",
          type: "text",
          placeholder: "Masukkan title",
          class: "w-full mb-4"
        }, null, 8, ["value"]),
        _createVNode(_component_SingleImageUpload, {
          label: "Banner",
          value: _ctx.form.banner.file,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.banner.file) = $event)),
          "file-url": _ctx.form.banner.fileUrl,
          "unique-id": "ImageBanner",
          accept: ".png, .jpg, .jpeg, .svg",
          class: "mb-4"
        }, null, 8, ["value", "file-url"]),
        _createVNode(_component_Autocomplete, {
          value: _ctx.form.idCategory,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.idCategory) = $event)),
          label: "Kategori",
          items: _ctx.categories,
          class: "w-full mb-4"
        }, null, 8, ["value", "items"]),
        _createVNode(_component_RadioGroup, {
          label: "Status",
          items: _ctx.listStatus,
          value: _ctx.form.isActive,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.isActive) = $event)),
          "unique-id": "FieldStatus"
        }, null, 8, ["items", "value"])
      ])
    ]),
    buttons: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: "button w-20 bg-theme-6 text-white mr-2",
          onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onCancelClick && _ctx.onCancelClick(...args)))
        }, " Batal "),
        _createElementVNode("button", {
          type: "button",
          class: "button w-20 bg-theme-1 text-white",
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onSaveClick && _ctx.onSaveClick(...args)))
        }, _toDisplayString(_ctx.isEditPage ? "Perbarui" : "Simpan"), 1)
      ])
    ]),
    _: 1
  }, 8, ["modal-id", "title"]))
}